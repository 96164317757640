<template>
  <fm-form-dialog
    form-title="请假申请分配"
    :open-dialog.sync="modal"
    :form-parms="formParms"
    :old-data="formData"
    :mask-closable="false"
    label-alone
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="modal = false">
  </fm-form-dialog>
</template>

<script>
import {
  askLeaveApplyRequest
} from '@/api'

export default {
  props: {
    data: { type: Object, defualt: () => null },
    applyTypeList: { type: Array, defualt: () => [] }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '审批类型',
          selectDatas: this.applyTypeList,
          key: 'applyType',
          check: {
            required: true
          }
        }]
        return data
      }
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      if (data.batchIdList && data.batchIdList.length > 0) {
        let pl = data.batchIdList.map(v => {
          askLeaveApplyRequest.assign(v, {
            applyType: data.applyType
          })
        })
        await Promise.all(pl)
      } else if (data.id) {
        await askLeaveApplyRequest.assign(data.id, {
          applyType: data.applyType
        })
      }
      resolve()
      this.$emit('dataUpdate', 'assign')
      this.modal = false
    }
  }
}
</script>
