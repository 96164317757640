<template>
  <div class="purchase-method-detail-batch-deal-block">
    <div class="right">
      <div class="sign-list">
        <div class="sign-list-search">
          <fm-radio-group v-model="currentStatus" @change="chooseDataIds = []" style="margin-right: 20px" v-if="statusList.length > 1">
            <fm-radio :value="item.status" :label="item.statusText" :key="item.status" v-for="item in statusList"></fm-radio>
          </fm-radio-group>
          <fm-radio-group v-model="currentType" @change="chooseDataIds = []" style="margin-right: 20px">
            <fm-radio :value="item.key" :label="item.label" :key="item.key" v-for="item in applyTypeList"></fm-radio>
          </fm-radio-group>
          <fm-checkbox @change="allCheckChange" v-model="allCheck">全选</fm-checkbox>
        </div>
        <div class="sign-item" v-for="item in showDataList" :key="item.id">
          <sign-item @clickItem="clickItem" :data="item" :active="chooseDataIds.includes(item.id)"></sign-item>
        </div>
      </div>
      <div class="input-area">
        <fm-input-new type="textarea" class="input-area-input" v-model="reamrk"></fm-input-new>
      </div>
      <div class="right-btns">
        <fm-btn @click="clickAction(item)" v-for="item in actions" :key="item.key">{{item.label}}</fm-btn>
      </div>
    </div>
    <submit-form @submit="submit" ref="batchDealBlockSubmitForm" :updateKeys="chooseAction ? chooseAction.updateKeys : null"></submit-form>
    <assign-form :applyTypeList="applyTypeList" @dataUpdate="dataUpdate" ref="batchAssignBlockForm" :data="{batchIdList: chooseDataIds}"></assign-form>
  </div>
</template>

<script>
import signItem from './signItem'
import submitForm from './submitForm'
import assignForm from './assignForm'

import {
  askLeaveApplyRequest
} from '@/api'

export default {
  components: {
    signItem,
    submitForm,
    assignForm
  },
  props: {
    applyTypeList: { type: Array, defualt: () => [] },
    dataList: { type: Array, defualt: () => [] }
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        this.chooseDataIds = []
        let statusList = []
        this.dataList.forEach(v => {
          if (!statusList.find(a => a.status === v.status)) {
            statusList.push({
              status: v.status,
              statusText: v.statusText
            })
          }
        })
        this.statusList = statusList
        if (this.statusList.length === 0) {
          this.currentStatus = null
        } else if (this.currentStatus == null || !this.statusList.find(v => v.status === this.currentStatus)) {
          this.currentStatus = this.statusList[0].status
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      chooseDataIds: [],
      statusList: [],
      currentType: 'd_0_3',
      chooseAction: null,
      currentStatus: null,
      reamrk: '',
      allCheck: false
    }
  },
  computed: {
    showDataList () {
      return this.dataList.filter(v => v.status === this.currentStatus && v.applyType === this.currentType)
    },
    actions () {
      return this.showDataList.length > 0 ? this.showDataList[0].actions.filter(v => v.key.includes('_switch_') || v.key === 'assign') : []
    }
  },
  methods: {
    allCheckChange (value) {
      if (value) {
        this.chooseDataIds = this.showDataList.map(v => v.id)
      } else {
        this.chooseDataIds = []
      }
    },
    async dataUpdate () {
      await this.$emit('dataUpdate')
    },
    async submit (p) {
      let parm = {
        statusActionKey: this.chooseAction.key,
        statusActionRecordId: this.chooseAction.statusActionRecordId,
        remark: this.reamrk,
      }
      if (this.chooseAction.updateKeys) {
        this.chooseAction.updateKeys.split(',').forEach(v => {
          parm[v] = p[v]
        })
      }
      let pall = []
      this.chooseDataIds.forEach(v => {
        pall.push(askLeaveApplyRequest.switchStatus(v, parm))
      })
      await Promise.all(pall)
      this.reamrk = ''
      this.dataUpdate()
    },
    clickItem (data) {
      if (this.chooseDataIds.includes(data.id)) {
        this.chooseDataIds = this.chooseDataIds.filter(v => v !== data.id)
      } else {
        this.chooseDataIds.push(data.id)
      }
    },
    async clickAction (action) {
      if (this.chooseDataIds.length === 0) {
        this.$notify({
          title: '系统提示',
          message: '请选择请假条',
          type: 'info'
        })
        return
      }
      if (action.key === 'assign') {
        this.$refs.batchAssignBlockForm.modal = true
        return
      }
      this.chooseAction = action
      if (action.updateKeys) {
        this.$refs.batchDealBlockSubmitForm.modal = true
        return
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该请假条吗?'})
        if (result) {
          this.submit()
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.purchase-method-detail-batch-deal-block {
  padding: 20px;
  display: flex;
  .sign-list-search {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .sign-list {
    display: flex;
    height: 50%;
    overflow-y: auto;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;
    .sign-item {
      width: 300px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .right {
    height: calc(100% - 20px);
    flex: 1;
    overflow-y: auto;
    border: 1px solid #e8eaec;;
    display: flex;
    flex-direction: column;
    .input-area {
      flex: 1;
      max-height: 100px;
      min-height: 50px;
      .input-area-input {
        margin: 0 20px;
        width: calc(100% - 40px);
        height: calc(100% - 20px);
      }
    }
    .right-btns {
      padding:10px 20px;
      display: flex;
      justify-content: space-around;
    }
  }
  .deal-recode {
    margin:0 20px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eaec;
    max-height: 30%;
    overflow-y: auto;
  }
  .base-info {
    margin:0 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eaec;
    max-height: 150px;
    overflow-y: auto;
  }
}
</style>