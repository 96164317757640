import { render, staticRenderFns } from "./dealBlock.vue?vue&type=template&id=76ac35fa&scoped=true&"
import script from "./dealBlock.vue?vue&type=script&lang=js&"
export * from "./dealBlock.vue?vue&type=script&lang=js&"
import style0 from "./dealBlock.vue?vue&type=style&index=0&id=76ac35fa&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76ac35fa",
  null
  
)

export default component.exports